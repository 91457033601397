<template>
  <b-card title="Relatórios"></b-card>
</template>
<script>
import breadcrumb from '@/common/utils/breadcrumb';
export default {
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
      { titulo: this.$t('FINANCEIRO.CAIXA') },
    ]);
  },
};
</script>
